*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}


.rdw-editor-toolbar {
  z-index: 9999 !important;
}

.custom-swal-container {
  z-index: 10000; /* Adjust the z-index value as needed */
}

.full-width-datepicker {
  width: 100%;
}

/* Add padding and border around the content */
.post-content {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
}

/* Heading styling */
.post-content h1, .post-content h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}

/* Text styling */
.post-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.post-content ol,
.post-content ul {
font-size: 16px;
line-height: 1.6; 
margin-bottom: 15px;
padding-left: 40px;
}

/* Image styling */
.post-content img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}
